import {Component, DestroyRef, inject, OnInit, ViewChild} from '@angular/core';
import {
  CheckUnsavedFormDialogService,
  CurrentSessionService,
  enumToOptions,
  getMembersWithPermissionsFormControls,
  IFormGroupCheckable,
  isAdmin,
  SharedFolderCreateUpdateDialogData,
  SharedFolderSelectorView,
  WizardComponent
} from '|shared';
import {ApiSharedFolderService, SharedFolderDto} from '|api/document';
import {OrganizationalStructureEntityType, SharedFolderPermissionLevel} from '|api/commons';
import {Observable} from 'rxjs';
import {AuthorizedEntityType} from '|api/core';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {IczFormArray, IczFormControl, IczFormGroup, IczValidators} from '@icz/angular-form-elements';
import {injectModalData, injectModalRef} from '@icz/angular-modal';

enum SharedFolderWizardStep {
  SHARED_FOLDER_SPECIFICATION = 'SHARED_FOLDER_SPECIFICATION',
  SHARED_FOLDER_PERMISSIONS = 'SHARED_FOLDER_PERMISSIONS',
  SHARED_FOLDER_HISTORY = 'SHARED_FOLDER_HISTORY',
}

@Component({
  selector: 'icz-create-update-shared-folder-dialog',
  templateUrl: './create-update-shared-folder-dialog.component.html',
  styleUrls: ['./create-update-shared-folder-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class CreateUpdateSharedFolderDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  private modalRef = injectModalRef<Nullable<SharedFolderDto>>();
  private destroyRef = inject(DestroyRef);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private currentSessionService = inject(CurrentSessionService);
  private apiSharedFolderService = inject(ApiSharedFolderService);
  protected dialogData = injectModalData<SharedFolderCreateUpdateDialogData>();

  @ViewChild(WizardComponent)
  wizardComponent!: WizardComponent;

  isNew = true;

  form = new IczFormGroup({
    name: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    parentId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    ...getMembersWithPermissionsFormControls(),
  });

  sharedFolderPermissionOptions = enumToOptions('sharedFolderPermissionLevel', SharedFolderPermissionLevel);

  formGroupsToCheck!: string[];

  get isAtSharedFolderSpecificationStep() {
    return this.wizardComponent?.currentStep.id === SharedFolderWizardStep.SHARED_FOLDER_SPECIFICATION;
  }

  get sharedFolder() {
    return this.dialogData.sharedFolder;
  }

  readonly SharedFolderWizardStep = SharedFolderWizardStep;

  ngOnInit(): void {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);

    if (this.dialogData.isCreateMode) {
      this.form.patchValue({
        parentId: this.sharedFolder?.id,
      });

      const permissionsControl = this.form.get('functionalPositions') as IczFormArray;
      const adminPermissionControl = permissionsControl.incrementSize();

      adminPermissionControl.patchValue({
        id: this.currentSessionService.currentUserFunctionalPosition!.id,
        code: this.currentSessionService.currentUserFunctionalPosition!.code,
        name: this.currentSessionService.currentUserFunctionalPosition!.name,
        permission: SharedFolderPermissionLevel.COMPLETE_CONTROL,
        isReadonly: true,
      });
    }
    else {
      this.isNew = false;
      this.form.patchValue({
        name: this.sharedFolder!.name,
        parentId: this.sharedFolder!.parentId,
      });
    }

    if (isAdmin(this.currentSessionService.currentUserFunctionalPosition!)) {
      this.form.get('parentId')!.clearValidators();
      this.form.updateValueAndValidity();
    }
  }

  goToPermissionsStep() {
    this.wizardComponent.nextStep();
  }

  submit() {
    const formValue = this.form.getRawValue();
    let request$: Observable<SharedFolderDto>;

    if (this.isNew) {
      request$ = this.apiSharedFolderService.sharedFolderCreate({
        body: {
          name: formValue.name!,
          parentId: formValue.parentId,
          sharing: formValue.functionalPositions
            .filter(
              fpPermissionAssignment => fpPermissionAssignment.id !== this.currentSessionService.currentUserFunctionalPosition!.id
            ).map(fpPermissionAssignment => ({
              sharedWith: [
                {
                  organizationalStructureEntityType: OrganizationalStructureEntityType.FUNCTIONAL_POSITION,
                  organizationalStructureEntityId: fpPermissionAssignment.id!,
                }
              ],
              permissionSetLevel: fpPermissionAssignment.permission as SharedFolderPermissionLevel,
              revoking: false,
            })),
        },
      });
    }
    else {
      request$ = this.apiSharedFolderService.sharedFolderUpdate({
        id: this.sharedFolder!.id,
        body: {
          name: formValue.name!,
          parentId: formValue.parentId,
        },
      });
    }

    this.loadingService.doLoading(
      request$,
      this,
    ).subscribe(sharedFolder => {
      this.modalRef.close(sharedFolder);
    });
  }

  cancel() {
    this.modalRef.close(null);
  }

  protected readonly AuthorizedEntityType = AuthorizedEntityType;
  protected readonly SharedFolderSelectorView = SharedFolderSelectorView;
}
