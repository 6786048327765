<icz-wizard [showSubmitBar]="false" [alwaysEnableAllSteps]="!isNew" [waiting]="loadingService.isLoading$(this) | async">
  <ng-template
    [iczWizardStep]="SharedFolderWizardStep.SHARED_FOLDER_SPECIFICATION"
    label="Pojmenování složky"
  >
    <div class="p-16">
      <div class="row gap-16" [formGroup]="form">
        <icz-form-field formControlName="name" label="Název složky" class="grow"></icz-form-field>
        <icz-shared-folder-selector
          [view]="SharedFolderSelectorView.MANAGEABLE_FOLDERS"
          [form]="form" controlName="parentId" class="grow"
          label="Nadřazená složka" [disabledSubtreeRootId]="sharedFolder?.id"
        ></icz-shared-folder-selector>
      </div>
      @if (!isNew) {
        <div class="row gap-16">
          <icz-readonly-field label="Založeno" [value]="sharedFolder?.auditInfo?.createdAt | localizedDate" class="grow"></icz-readonly-field>
          <icz-readonly-field label="Poslední úprava" [value]="sharedFolder?.auditInfo?.modifiedAt | localizedDate" class="grow"></icz-readonly-field>
        </div>
      }
    </div>
  </ng-template>
  <ng-template
    [iczWizardStep]="SharedFolderWizardStep.SHARED_FOLDER_PERMISSIONS"
    label="Oprávnění ke složce"
  >
    @if (isNew) {
      <icz-members-with-permissions-table
        [form]="form"
        [permissionOptions]="sharedFolderPermissionOptions"
        [disableSelfPermissionAssignment]="true"
        [updateModeMemberPermissions]="[]"
        permissionTooltipTranslationPrefix="SHARED_FOLDER"
      ></icz-members-with-permissions-table>
    }
    @else {
      <icz-permission-settings-table
        class="grow h-full"
        tableTitle="Oprávnění ke sdílené složce"
        [disableOrgUnitPermissions]="true"
        [entityId]="sharedFolder!.id"
        [entityType]="AuthorizedEntityType.SHARED_FOLDER"
      ></icz-permission-settings-table>
    }
  </ng-template>
  <ng-template
    [iczWizardStep]="SharedFolderWizardStep.SHARED_FOLDER_HISTORY"
    [stepHidden]="isNew"
    label="Historie změn"
  >
    <icz-transactions
      type="entity"
      [identifier]="sharedFolder!.uid!.identifier"
      [idSource]="sharedFolder!.uid!.idSource"
    ></icz-transactions>
  </ng-template>
</icz-wizard>

<icz-form-buttons>
  <ng-container leftButtons>
    @if (isNew && isAtSharedFolderSpecificationStep) {
      <icz-button iczFormSubmit
                  [disabled]="loadingService.isLoading(this)"
                  (onAction)="goToPermissionsStep()"
                  primary label="K nastavení oprávnění"
      ></icz-button>
    }
    @else {
      <icz-button iczFormSubmit
                  [disabled]="loadingService.isLoading(this)"
                  (onAction)="submit()"
                  primary [label]="isNew ? 'Založit' : 'Uložit změny'"
      ></icz-button>
    }
  </ng-container>
  <icz-button rightButtons
              [disabled]="loadingService.isLoading(this)"
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
